import React from 'react';
import Media from 'react-media';




const Cyber = ({  }) => {

  return (
    <React.Fragment>
      <div className="main mt-0">
        <div className='row'>
        <Media query="(min-width: 768px)" render={() =>
          (
            <div className='col-lg-10 offset-1'>
              <a href='https://www.lider.cl/catalogo/category/Mundo_Beb%C3%A9'><img className='img-fluid w-100' src='https://s3.babytuto.com/b815741509ad1d53fea97b83ba7999e5.png'/></a>
            </div>

          )}
        />

        <Media query="(max-width: 767px)" render={() =>
                  (
         
                    <a href='https://www.lider.cl/catalogo/category/Mundo_Beb%C3%A9'><img className='img-fluid w-100' src='https://s3.babytuto.com/a348841aaa1a66becf3854baa7efcb94.png'/></a>


        )}/>
          
        </div>
      </div>



    </React.Fragment>
  )
};

export default Cyber;


