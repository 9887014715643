import React, { useLayoutEffect } from 'react';
import Header from './components/transversales/Header';
import Body from './components/pages/Cyber';
import { BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap';



const App: React.FC = () => {
  const [data, setData] = React.useState(null);
  let check = true;
  
  React.useEffect(()=>{
    if(check){
      check = false;
    }
  },[]);

  return (
    <Router>
      <Body />
    </Router>
  );
};

export default App;
